import React from 'react';
import '../../App.css';
import './HeroSection.css';
import Test from "./Force-directed";
import {Button} from "./Button";
import Command from "./Command";


function HeroSection() {
    return (
        <>
            <div className="hero-container">

                <div className="force-container">
                    <div className="intro">

                        <div className="big-title">
                            赋予机器智能 <br/>
                            提升人类智慧 助力世界创新
                        </div>


                        <div className="summary">
                            Endow machines with intelligence enhance human wisdom <br/>
                            and empower world innovation
                        </div>

                        <div className="about-btn">
                            <Button link="/about" buttonStyle={`btn-primary`}>关于我们</Button>
                            <Button link="/about" buttonStyle={`btn-outline`}>联系我们</Button>
                        </div>
                    </div>

                    {/*<div className="tools-container">*/}
                    {/*    <div className="command-container">*/}
                    {/*        <Command>pip install flask</Command>*/}
                    {/*        <div style={{marginRight: 20}}></div>*/}
                    {/*        <Command>flask --app main run</Command>*/}
                    {/*    </div>*/}

                    {/*    <div className="get-start">*/}
                    {/*        <Button link="/" buttonStyle={`btn-start`}>Get Started</Button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

            </div>
        </>
    );
}

export default HeroSection;
