import React from 'react';
import './About.css'


function About() {

    return (
        <>
            <div className="about-container">

                <div className="pc-container">

                    <div className="body-container">
                        <div className="content-about">
                            忆生科技 (Engram)
                            <div className="content-about-small">
                                <div>
                                    <div style={{'lineHeight': '1.5em'}}>
                                        诞生于2023年，由全球顶级计算机视觉、人工智能、统计与应用数学专家马毅教授领衔，核心团队成员来自微软、加州大学伯克利分校、清华大学等。
                                        背靠世界级的前沿基础研究，忆生科技致力于打造完整、自主、自洽的机器智能系统，以求高效、安全地服务于人类的生产与生活，促进可持续的创新与发展。
                                        <br/>
                                        <br/>
                                        基于独创领先的学术成果，我们相信自主智能的系统应具备从外部世界自主获取新的知识与规律的能力，持续自我提升改进，并以此指导预测和决策。
                                        <br/>
                                        <br/>

                                        依托长期先发的技术积淀，我们的算法平台与工具具备框架完整、高度可解释性等独特优势，且在应用层面可大大降低对算力、能耗等外部资源的依赖程度。
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*我们是谁*/}
                        {/*忆生科技 (Engram) 诞生于2023年，由全球顶级计算机视觉、人工智能、统计与应用数学专家马毅教授领衔，核心团队成员来自微软、加州大学伯克利分校、清华大学等。*/}
                        {/*背靠世界级的前沿基础研究，忆生科技致力于打造完整、自主、自洽的机器智能系统，以求高效、安全地服务于人类的生产与生活，促进可持续的创新与发展。*/}
                        {/*基于独创领先的学术成果，我们相信自主智能的系统应具备从外部世界自主获取新的知识与规律的能力，持续自我提升改进，并以此指导预测和决策。*/}
                        {/*依托长期先发的技术积淀，我们的算法平台与工具具备框架完整、高度可解释性等独特优势，且在应用层面可大大降低对算力、能耗等外部资源的依赖程度。*/}


                        {/*<div className="content-contact">*/}
                        {/*    <div className="content-contact-container">*/}

                        {/*        <div className="content-contact-big">*/}
                        {/*            联系我们*/}
                        {/*        </div>*/}

                        {/*        <div className="content-contact-small">*/}

                        {/*            <div className="grid-1">*/}
                        {/*                <img src="/images/icon/address.jpg" width="20"*/}
                        {/*                     height="20"/> &nbsp;&nbsp;北京市朝阳区东四环中路60号远洋国际中心*/}
                        {/*            </div>*/}

                        {/*            <div className="grid-2">*/}
                        {/*                <img src="/images/icon/address.jpg" width="20"*/}
                        {/*                     height="20"/> &nbsp;&nbsp;上海市徐汇区新漕河泾国际商务中心*/}
                        {/*            </div>*/}

                        {/*            <div className="grid-3">*/}
                        {/*                <img src="/images/icon/address.jpg" width="20"*/}
                        {/*                     height="20"/> &nbsp;&nbsp;深圳市南山街道梦海大道5073号前海国际人才港*/}
                        {/*            </div>*/}

                        {/*            <div className="grid-5">*/}
                        {/*                <img src="/images/icon/email.jpg" width="20"*/}
                        {/*                     height="20"/> &nbsp;&nbsp;联系邮箱：AGI@transcengram.com*/}
                        {/*            </div>*/}
                        {/*            <div className="grid-6">*/}
                        {/*                <img src="/images/icon/email.jpg" width="20"*/}
                        {/*                     height="20"/> &nbsp;&nbsp;人才邮箱：recruit@transcengram.com*/}
                        {/*            </div>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}

                        {/*    <div className="content-qrcode">*/}
                        {/*        <img src="/images/qrcode.jpg" width="120" height="120"/>*/}
                        {/*        <div className="content-qrcode-small">*/}
                        {/*            公众号二维码*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*</div>*/}


                    </div>

                </div>

            </div>
        </>
    )
}

export default About