import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const closeMobileMenu = () => setClick(false);


    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>

                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src="/images/Logo_白色@2x.png" width="83" height="20"/>
                    </Link>


                    <ul className="nav-menu">

                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                忆生使命
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link to='/core' className='nav-links' onClick={closeMobileMenu}>
                                核心技术
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link to='/industry' className='nav-links' onClick={closeMobileMenu}>
                                行业合作
                            </Link>
                        </li>


                        <li className='nav-item'>
                            <Link to='/join' className='nav-links' onClick={closeMobileMenu}>
                                加入我们
                            </Link>
                        </li>

                        {/*<li className='nav-item'>*/}
                        {/*    <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>*/}
                        {/*        Contact Us*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                关于我们
                            </Link>
                        </li>

                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
