import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className='mobile-footer-container'>
            <div>
                <a href="https://beian.miit.gov.cn/">粤ICP备2024199937号</a>
            </div>
            <div>
                Copyright © 2023-2024 Engram. All Rights Reserved.
            </div>
        </div>
    );
}

export default Footer;
