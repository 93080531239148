import React from 'react';
import './Industry.css'

function Industry() {

    return (
        <>
            <div className="mobile-industry-container">
                <div className="mobile-container">
                    <div className="mobile-industry-title">
                        行业合作
                    </div>

                    <div className="mobile-industry-summary">
                        自成立以来，忆生科技持续与行业龙头及标杆客户建立示范性行业场景应用合作，并以行业标杆应用为指导助力模型应用企业发展，共建新一代智能生态。公司目前产业落地方向主要有工业、能源、交通、教育、医疗以及机器人等领域，诚邀各界产业同仁共商新一代智能系统应用的无限可能！
                        <br/>
                        <br/>
                        联系方式：AGI@transcengram.com
                    </div>
                </div>
            </div>
        </>
    )
}

export default Industry