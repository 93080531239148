import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            {/*<div className="mobile-navbar">*/}
            <div className="mobile-navbar-container">

                <Link to='/' className="mobile-navbar-logo" onClick={closeMobileMenu}>
                    <div>
                        <img src="/images/Logo_白色@2x.png" width="83" height="20"/>
                    </div>
                </Link>


                <div className="mobile-menu-icon" onClick={handleClick}>
                    <img src="/images/icon/编组@3x.png" className={click ? 'fas mobile-fa-times' : 'fas mobile-fa-bars'} width="32" height="32"/>

                </div>


                <ul className={click ? 'mobile-nav-menu mobile-active' : 'mobile-nav-menu'}>
                    <li className="mobile-nav-item">
                        <Link to='/' className="mobile-nav-links" onClick={closeMobileMenu}>
                            忆生使命
                        </Link>
                    </li>

                    <li className="mobile-nav-item">
                        <Link to='/core' className="mobile-nav-links" onClick={closeMobileMenu}>
                            核心技术
                        </Link>
                    </li>

                    <li className="mobile-nav-item">
                        <Link to='/industry' className="mobile-nav-links" onClick={closeMobileMenu}>
                            行业合作
                        </Link>
                    </li>

                    <li className="mobile-nav-item">
                        <Link to='/join' className="mobile-nav-links" onClick={closeMobileMenu}>
                            加入我们
                        </Link>
                    </li>

                    <li className="mobile-nav-item">
                        <Link
                            to='/about' className="mobile-nav-links" onClick={closeMobileMenu}>
                            关于我们
                        </Link>
                    </li>

                    {/*<li className="mobile-nav-item">*/}
                    {/*    <Link*/}
                    {/*        to='/contact'*/}
                    {/*        className="mobile-nav-links"*/}
                    {/*        onClick={closeMobileMenu}*/}
                    {/*    >*/}
                    {/*        Contact*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
            </div>
            {/*</div>*/}

        </>
    );
}

export default Navbar;
