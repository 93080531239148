import React from 'react';
import './Contact.css'

function Contact() {

    return (
        <>
            <div className="contact-container">

                <div className="pc-container">

                    <div className="body-container">
                        {/*<div className="content-contact">*/}
                        {/*    忆生科技 Engram*/}
                        {/*    <div className="content-contact-small">*/}
                        {/*        忆生科技(Engram) 诞生于2023年，由全球顶级计算机视觉，人工智能，统计与应用数学专家马毅教授创立，团队成员来自微软，加州大学伯克利分校，清华大学等。背靠世界级的前沿基础研究，忆生科技致力于打造完整，自主，自洽的机器智能系统，以求高效，安全地服务于人类的生活与可持续的创新与发展。*/}
                        {/*        <br/>*/}
                        {/*        <br/>*/}
                        {/*        目前，忆生科技与香港大学等学术与科研机构达成战略合作。*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="content-contact">
                            <div className="content-contact-container">

                                <div className="content-contact-big">
                                    联系我们
                                </div>

                                <div className="content-contact-small">

                                    <div className="grid-1">
                                        <img src="/images/icon/address.jpg" width="20" height="20"/> &nbsp;&nbsp;北京市朝阳区东四环中路60号远洋国际中心
                                    </div>

                                    <div className="grid-2">
                                        <img src="/images/icon/address.jpg" width="20" height="20"/> &nbsp;&nbsp;上海市徐汇区新漕河泾国际商务中心
                                    </div>

                                    <div className="grid-3">
                                        <img src="/images/icon/address.jpg" width="20" height="20"/> &nbsp;&nbsp;深圳市南山街道梦海大道5073号前海国际人才港
                                    </div>

                                    <div className="grid-5">
                                        <img src="/images/icon/email.jpg" width="20" height="20"/> &nbsp;&nbsp;联系邮箱：AGI@transcengram.com
                                    </div>
                                    <div className="grid-6">
                                        <img src="/images/icon/email.jpg" width="20" height="20"/> &nbsp;&nbsp;人才邮箱：recruit@transcengram.com
                                    </div>
                                </div>

                            </div>

                            <div className="content-qrcode">
                                <img src="/images/qrcode.jpg" width="120" height="120"/>
                                <div className="content-qrcode-small">
                                    公众号二维码
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>
        </>
    )
}

export default Contact