import React from 'react';
import './CoreProjects.css'

function CoreProjects() {

    return (
        <>
            <div className="mobile-core-projects-container">
                <div className="mobile-container">
                    <div className="mobile-core-projects-title">
                        核心技术
                    </div>

                    <div className="mobile-core-projects-summary">
                        <div>
                            <div style={{'lineHeight': '1.5em'}}>

                                <div style={{'marginBottom': '10px'}}>
                                            <span style={{'fontWeight': 700}}>
                                            记忆生成：
                                            </span>
                                    白盒深度学习构架CRATE，以最大化信息增益为目的，重新构建具有可解释性的、更为高效和完整的神经网络系统，拥有自我纠错和自我反省机制，具有自主学习能力，全方位模拟人的记忆生成系统。
                                    <br/>
                                </div>


                                <div style={{'marginBottom': '10px'}}>
                                            <span style={{'fontWeight': 700}}>
                                            感知进化：
                                            </span>
                                    利用最新的传感技术和数据处理算法，使系统能够模拟人类的视觉等感知机制，实现对复杂环境的精准识别、重建和记忆。
                                    <br/>
                                </div>


                                <div style={{'marginBottom': '10px'}}>
                                            <span style={{'fontWeight': 700}}>
                                            决策优化：
                                            </span>
                                    通过先进的强化与模仿学习，不断优化机器的认知能力，将人类的经验和知识转化为机器可理解的语言，使其在理解、推理和决策方面更加接近人类。
                                    <br/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoreProjects