import React from 'react';
import './Join.css'

function Join() {

    return (
        <>
            <div className="mobile-join-container">
                <div className="mobile-container">
                    <div className="mobile-join-title">
                        加入我们
                    </div>

                    <div className="mobile-join-summary">
                        忆生科技的创始团队选择了一个伟大的且具有挑战的目标：实现自主、通用的人工智能(AGI)。我们坚信——正确的方向充满艰辛，但正确的道路不会孤独。
                        我们邀请志同道合者加入我们，从 AI 1.0（Artificial Intelligence）到 AI 2.0（Autonomous Intelligence），一起打造新一代的人工智能技术，成为这一次科技革命的缔造者！
                        <br/>
                        <br/>
                        联系方式：recruit@transcengram.com
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Join