import React from 'react';
import './Button.css';
import {Link} from 'react-router-dom';


function Button({children, onClick, buttonStyle, buttonSize, link = "/about"}) {
    return (
        <>
            <Link to={link}>
                <button
                    className={`btn ${buttonStyle} ${buttonSize}`} onClick={onClick}>{children}
                </button>
            </Link>
        </>
    );
}

export {Button}