import React from 'react';
import '../../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Cards from "../Cards";
import CardItem from "../CardItem";
import ParagraphTitle from "../ParagraphTitle";

function Home() {
    return (
        <>
            <HeroSection/>
            {/*<ParagraphTitle content="What’s possible with Engram"></ParagraphTitle>*/}

            {/*<Cards>*/}
            {/*    <CardItem*/}
            {/*        title="Speed up Python"*/}
            {/*        icon="/images/icon/python-acc.svg"*/}
            {/*        image="/images/python-code.svg"*/}
            {/*        action="Try now >"*/}
            {/*        content="With Engram, businesses unlock their potential through AI, transforming data into actionable insights and strategic growth."*/}
            {/*    >*/}

            {/*    </CardItem>*/}


            {/*    <CardItem*/}
            {/*        title="Numerical simulation"*/}
            {/*        icon="/images/icon/numerical-simulation.svg"*/}
            {/*        image="/images/numerical-simulation.png"*/}
            {/*        action="Learn more >"*/}
            {/*        content="Engram, a trailblazer in AI, revolutionizes industries with cutting-edge solutions, empowering businesses to thrive in the digital era."*/}
            {/*    >*/}
            {/*    </CardItem>*/}


            {/*    <CardItem*/}
            {/*        title="3D rendering"*/}
            {/*        icon="/images/icon/3d-render.svg"*/}
            {/*        image="/images/3d-render.jpg"*/}
            {/*        action="Learn more >"*/}
            {/*        content="At Engram, we harness the power of artificial intelligence to create innovative technologies that redefine tomorrow."*/}
            {/*    >*/}
            {/*    </CardItem>*/}

            {/*    <CardItem*/}
            {/*        title="Computer vision"*/}
            {/*        icon="/images/icon/robot-vision.svg"*/}
            {/*        image="/images/robot-vision.png"*/}
            {/*        action="Learn more >"*/}
            {/*        content="At Engram, we harness the power of artificial intelligence to create innovative technologies that redefine tomorrow."*/}
            {/*    >*/}
            {/*    </CardItem>*/}
            {/*</Cards>*/}

        </>
    );
}

export default Home;
