import React from 'react';
import '../../App.css';
import {Button} from './Button';
import './HeroSection.css';

function HeroSection() {
    return (
        <div className="mobile-hero-container">

            <div className="mobile-intro">
                <div className="mobile-title">
                    赋予机器智能
                </div>

                <div className="mobile-small-title">
                    提升人类智慧 助力世界创新
                </div>

                <div className="mobile-summary-title">
                    Endow machines with intelligence enhance human wisdom <br/>and empower world innovation
                </div>
            </div>

        </div>
    );
}

export default HeroSection;
