import React from 'react';
import './App.css';
import {default as Navbar_PC} from './pc/components/Navbar';
import {default as Home_PC} from './pc/components/pages/Home';
import {default as CoreProjects_PC} from './pc/components/pages/CoreProjects';
import {default as Industry_PC} from './pc/components/pages/Industry';
import {default as About_PC} from './pc/components/pages/About';
import {default as Join_PC} from './pc/components/pages/Join';
import {default as Contact_PC} from './pc/components/pages/Contact';
import {default as Footer_PC} from "./pc/components/Footer";


import {default as Navbar_Mobile} from './mobile/components/Navbar';
import {default as Home_Mobile} from './mobile/components/pages/Home';
import {default as About_Mobile} from './mobile/components/pages/About';
import {default as CoreProjects_Mobile} from './mobile/components/pages/CoreProjects';
import {default as Industry_Mobile} from './mobile/components/pages/Industry';
import {default as Join_Mobile} from './mobile/components/pages/Join';


import {default as Footer_Mobile} from './mobile/components/Footer';


import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


function App() {
    return (
        <>
            <div className="pc">
                <Router>
                    <Navbar_PC/>
                    <Switch>
                        <Route path='/' exact component={Home_PC}/>
                        <Route path='/core' exact component={CoreProjects_PC}/>
                        <Route path='/industry' exact component={Industry_PC}/>
                        <Route path='/about' exact component={About_PC}/>
                        <Route path='/join' exact component={Join_PC}/>
                        <Route path='/contact' exact component={Contact_PC}/>
                    </Switch>
                    <Footer_PC></Footer_PC>

                </Router>
            </div>

            <div className="mobile">
                <Router>
                    <Navbar_Mobile/>
                    <Switch>
                        <Route path='/' exact component={Home_Mobile}/>
                        <Route path='/about' component={About_Mobile}/>
                        <Route path='/core' component={CoreProjects_Mobile}/>
                        <Route path='/industry' component={Industry_Mobile}/>
                        <Route path='/join' component={Join_Mobile}/>
                    </Switch>
                    {/*<Footer_Mobile></Footer_Mobile>*/}
                </Router>
            </div>
        </>
    );
}

export default App;
